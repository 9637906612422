<template>
  <div style="padding:16px">
    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" justify="space-around">
        <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col>
        <el-col :span="6">
          <el-input
            placeholder="请输入内容"
            v-model="queryInfo.condition.queryKey"
            class="input-with-select"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getList"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table
        border
        ref="table"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        :height="height"
      >
        <el-table-column
          label="序号"
          type="index"
          width="50"
          align="center"
        ></el-table-column>

        <!-- <el-table-column label="" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>
        <el-table-column label="分类编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryCode }}</template>
        </el-table-column> -->
        <el-table-column label="分类名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.categoryName }}</template>
        </el-table-column>
        <el-table-column label="级别" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.level }}</template>
        </el-table-column>
        <!-- <el-table-column label="上级编码" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.parentCode }}</template>
        </el-table-column> -->
        <el-table-column label="上级名称" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.parentName }}</template>
        </el-table-column>
        <!-- <el-table-column
          label="是否可见(0:否;1:是)"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.isVisible }}</template>
        </el-table-column> -->
        <el-table-column label="排序" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.seq }}</template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.createDate }}</template>
        </el-table-column>
        <!-- <el-table-column
          label="创建人编码"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">{{ scope.row.createBy }}</template>
        </el-table-column> -->
        <el-table-column label="操作" width="135" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small"
              >编辑</el-button
            >
            <el-popconfirm
              title="确定删除吗？"
              @confirm="handleDelte(scope.row.categoryCode)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <el-dialog
      :title="labelType == 'add' ? '新增' : '编辑'"
      :visible.sync="showDialog"
      width="800px"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">
          <!-- <el-col :span="12">
              <el-form-item label="：" prop="siteName">
                <el-input v-model="form.id" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          <el-col :span="12">
              <el-form-item label="分类编码：" prop="siteName">
                <el-input v-model="form.categoryCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="分类名称：" prop="siteName">
              <el-input
                v-model="form.categoryName"
                placeholder="请输入分类名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="级别：" prop="siteName">
                <el-input v-model="form.level" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
          <el-col :span="12">
              <el-form-item label="上级编码：" prop="siteName">
                <el-input v-model="form.parentCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="上级名称：" prop="siteName">
              <el-cascader
                :disabled="labelType =='edit' ? true : false "
                  v-model="form.categoryCode"
                  ref="category"
                  :options="options"
                  :props="{ expandTrigger:'click', value:'categoryCode', label:'categoryName', children:'children',checkStrictly: true}"
                  @change="chooseName"
                  clearable
                ></el-cascader>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="是否可见(0:否;1:是)：" prop="siteName">
                <el-input v-model="form.isVisible" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
          <el-col :span="12">
            <el-form-item label="排序：" prop="siteName">
              <el-input v-model="form.seq" placeholder="请输入排序"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
              <el-form-item label="创建时间：" prop="siteName">
                <el-input v-model="form.createDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建人编码：" prop="siteName">
                <el-input v-model="form.createBy" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col> -->
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="closeForm">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 10,
        pageCount: 1,
        currPage: 1,
        condition: {}
      },
      form: {
        categoryName:'',
        seq:'',
        categoryCode:''
      },
      showDialog: false,
      tableData: [],  // 表格数据
      height: window.innerHeight - 256, // 表格高度
      headerHeight: 0,
      options: []
    }
  },

  created () {
    this.getList()
  },

  mounted () {
    this.$nextTick(() => {
      // 获取定义ref属性 和 元素高度
      this.headerHeight = this.$refs.search_d.offsetHeight
      window.addEventListener('resize', this.getHeight())
    })
  },

  methods: {
    // 监听表格高度
    getHeight () {
      this.height = window.innerHeight - this.headerHeight - 256
    },

    // 获取数据
    getList () {
      var that = this
      that.$http
        .post('/productCategory/list', that.queryInfo)
        .then(function (response) {
          if (response.data.code == 200) {
            that.total = response.data.data.count
            that.tableData = response.data.data.data
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
          }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
    },

    // 保存
    handleSave () {
      var that = this
      that.$http
        .post('/productCategory/save', that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: '提示',
              message: '保存成功',
              showClose: true
            })
            that.showDialog = false
            that.getList()
          } else {
            that.$notify.info({
              title: '提示',
              message: response.data.message,
              showClose: true
            })
          }
        })
        .catch(function (error) {})

      that.getList()
    },

    // 修改
    edit (row) {
      var that = this
      that.showDialog = true
      that.getProduct()
      that.labelType = 'edit'
      that.form = JSON.parse(JSON.stringify(row))
      that.$http
      .post('/productCategory/queryOne',row)
      .then(function(response){
        // console.log(response);
        if(response.data.code == 200) {
          that.form.categoryCode = response.data.data.parentCode
        }
      })
    },

    // 删除当前行
    //    handleDelte (id) {
    //      del({ id: id }).then(res => {
    //        if (res.code == 200) {
    //         this.$message.success('删除成功')
    //         this.getList()
    //       }
    //      })
    //    },

    // 删除用户
    async handleDelte (id) {
      const confirmResult = await this.$confirm('确定删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirmResult === 'confirm') {
        var that = this
        that.$http
          .post('/productCategory/delete', { categoryCode: id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.$notify.success({
                title: '提示',
                message: '删除成功',
                showClose: true
              })
              that.getList()
            } else {
              that.$notify.info({
                title: '提示',
                message: response.data.message,
                showClose: true
              })
            }
          })
      }
    },

    // 新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true
      this.form = {
        categoryName: '',
        seq: '',
        categoryCode:''
      },
      this.getProduct()
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val
      this.getList()
    },

    // 获取当前页面 
    handleCurrentChange (val) {
      this.queryInfo.currPage = val
      this.getList()
    },

    // 获取产品分类
    getProduct () {
      var that = this
      that.$http.post('/productCategory/queryTreeList', that.form)
        .then(function (response) {
          if (response.data.code == 200) {
            that.options = response.data.data
            // that.$notify.success({
            //   title: "提示",
            //   message: "获取数据成功",
            //   showClose: true,
            // });
          }else {
            that.$notify.info({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
    },

    // 关闭表单
    closeForm () {
      this.showDialog = false
      this.form = {
        categoryName: '',
        seq: '',
        categoryCode:''
      }
    },

    // 选择上级名称
    chooseName (val) {
      if(val.length === 0) {
        this.form.parentCode = ''
       this.form.parentName = ''
      }
      // console.log('’val',val);
      let categoryData = this.$refs.category.getCheckedNodes()

      // this.form.parentCode = categoryData[0].data.categoryCode
      // this.form.parentName = categoryData[0].data.categoryName
      if (categoryData.length <= 0) {
       this.form.parentCode = ''
       this.form.parentName = ''
      } else {
       this.form.parentCode = categoryData[categoryData.length - 1].data.categoryCode
       this.form.parentName = categoryData[categoryData.length - 1].data.categoryName
      }
    }
  }
}
</script>

<style scoped></style>
